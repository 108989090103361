import React from 'react';
import styled from 'styled-components';
import { StyledButtonLink } from './Button';

const StyledIntroduction = styled.div`
  margin-top: 3rem;
  text-align: center;

  h1 {
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  p.subtitle {
    color: #ccc;
    font-size: 1.5rem;
  }

  p {
    color: #909090;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
`;

export default function Introduction({
  title,
  subtitle,
  children,
  hideBtn = false,
  btnText = 'Get In Touch',
}) {
  return (
    <StyledIntroduction>
      <h1>{title}</h1>
      {subtitle && <h3 className="subtitle">{subtitle}</h3>}
      {children}

      {!hideBtn && <br />}
      {!hideBtn && <StyledButtonLink to="/contact">{btnText}</StyledButtonLink>}
    </StyledIntroduction>
  );
}
