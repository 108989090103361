import React from 'react';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';
import styled from 'styled-components';
import Layout from '../../shared/components/Layouts/Inner';
import ContainerFixed from '../../shared/components/Layouts/ContainerFixed';
import SEO from '../../shared/components/SEO';
import Introduction from '../../shared/components/Introduction';
import useScript from '../../shared/hooks/useScript';
import Loading from '../../shared/images/gif/loading.gif';

const Booking = styled.div`
  padding-bottom:2rem;
  iframe {
    border: none;
    background: url('${Loading}') no-repeat;
    background-position: center 10%;
    background-size:40px;
  }
`;

const BookNow = () => {
  useScript('https://northtynesidemun.launch27.com/jsbundle');

  const title = 'Book Now - North Tyneside';
  return (
    <Layout url="book-now" darkMode>
      <SEO title={title} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ContainerFixed>
        <Introduction title={title} hideBtn />
        <Booking>
          <Iframe
            url="https://northtynesidemun.launch27.com/?w_cleaning"
            width="100%"
            height="2739px"
            id="booking-widget-iframe"
            display="initial"
            position="relative"
            loading="true"
          />
        </Booking>
      </ContainerFixed>
    </Layout>
  );
};

export default BookNow;
